<template>
  <section id="dashboard" v-if="!ability.can('read', 'actionlist')">
    <div>
      <b-row class="">
        <b-col xl="6" md="6">
          <b-card class="card-statistics p-1" style="min-height: 200px">
            <div class="d-flex justify-content-between">
              <h4 class="font-weight-bolder">Back Up Data</h4>
              <b-card-text class="update-text font-small-2 mb-0">
                <!-- Updated 1 day ago -->
              </b-card-text>
            </div>
            <b-card-body class="">
              <b-row class="info-wrapper">
                <b-col xl="12" class="">
                  <b-media>
                    <b-media-body class="my-auto color0">
                      <h4 class="font-weight-bolder mb-0" style="color: green; background-color: white">
                        <b-button variant="primary" @click="downloadBackupStorage">
                          Download Backup Storage
                        </b-button>
                      </h4>
                      <br />
                      <h4 class="font-weight-bolder mb-0" style="color: green; background-color: white">
                        <b-button variant="primary" @click="downloadDatabaseBackupJSON">
                          Download Database Backup JSON
                        </b-button>
                      </h4>
                      <br />
                      <b-button variant="primary" @click="downloadDatabaseBackupSQL">
                        Download Database Backup SQL
                      </b-button>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </section>

</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import VueApexCharts from "vue-apexcharts";
import ability from "@/libs/acl/ability";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BButton,
  },
  data() {
    return {
    };
  },
  methods: {
    downloadDatabaseBackupJSON() {
      console.log("Downloading database backup...");
      store.dispatch("app/downloadDatabaseBackupJSON")
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'backup.json'); // Set the file name
          document.body.appendChild(link);
          link.click(); // Trigger the download
          link.remove(); // Clean up
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downloadDatabaseBackupSQL() {
      console.log("Downloading database backup...");
      store.dispatch("app/downloadDatabaseBackupSQL")
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'backup.sql'); // Set the file name
          document.body.appendChild(link);
          link.click(); // Trigger the download
          link.remove(); // Clean up
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downloadBackupStorage() {
      console.log("Downloading backup storage...");
      store.dispatch("app/downloadBackupStorage")
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' })); // Specify the MIME type
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'backup.zip'); // Set the file name
          document.body.appendChild(link);
          link.click(); // Trigger the download
          link.remove(); // Clean up
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // fetchDashboardData() {
    //   store
    //     .dispatch("app/fetchDashboardData")
    //     .then((response) => {
    //       this.dataStaff.series[0].data = Object.values(response.data.user);
    //       this.dataStaff.num = response.data.total_users;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
  created() {
    this.fetchDashboardData();
  },
  setup() {
    const customerData = ref([]);
    const salesData = ref([]);
    const couponData = ref([]);


    const fetchCustomerData = () => {
      store.dispatch("app/fetchDashboardData")
        .then((response) => {
          customerData.value = response.data.customers;
          salesData.value = response.data.sales;
          couponData.value = response.data.coupons;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      fetchCustomerData();
    });

    return {
      ability,
      customerData,
      salesData,
      couponData,
    };
  },
};
</script>

<style lang="scss" scoped>
.primary {
  color: red;
}
</style>
